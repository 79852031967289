import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type State = Readonly<{
	updateReady: boolean;
}>;

const initialState: State = {
	updateReady: false,
} as const;

class AutoUpdateStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'UPDATE_READY':
				return this.handleUpdateReady();
			default:
				return false;
		}
	}

	isUpdateReady(): boolean {
		return this.state.updateReady;
	}

	private handleUpdateReady(): boolean {
		this.setState((prevState) => ({
			...prevState,
			updateReady: true,
		}));
		return true;
	}
}

export default new AutoUpdateStore();
